<template>
  <div class="app-container">
    <avue-crud
      ref="crud"
      class="search-form flex-one-page"
      :option="tableOption"
      :data="tableData"
      :tableLoading="tableLoading"
      :resetMergeData="resetMergeData"
      :page="tablePage"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
    >
      <template #categorySearchForm="{ form }">
        <ProtoTypeTreeSelect
          placeholder="请选择分类"
          size="mini"
          class="w100"
          :selectedValue.sync="form['categoryId']"
          :maxHeight="150"
          :otherIncluded="true"
          @change="searchChange"
        />
      </template>
      <template #userName="{ row }">
        <div v-if="!isUnsubscribe(row)">{{ getNickName(row) }}</div>
        <div v-else>——</div>
      </template>
      <template #accountName="{ row }">
        <div>
          {{ getAccountName(row) }}
          <span v-if="isUnsubscribe(row)" class="danger">（已注销）</span>
        </div>
      </template>
      <template #email="{ row }">
        <div v-if="!isUnsubscribe(row)">{{ getEmail(row) }}</div>
        <div v-else>——</div>
      </template>
      <template #remark="{ row }">
        <div class="user-behavior-warpper">
          <el-tooltip
            popper-class="user-behavior-tips"
            placement="top"
            effect="light"
            :open-delay="300"
            :disabled="!row.remark"
            :content="row.remark"
          >
            <div class="cut">
              {{ row.remark || '未标记' }}
            </div>
          </el-tooltip>
        </div>
      </template>
      <template #platform="{ row }">
        {{ getPlatform(row) }}
      </template>
      <template #categoryName="{ row }">
        {{ getcategoryName(row) }}
      </template>

      <template #time="{ row }">
        {{ parseTime(getTime(row)) }}
      </template>
      <template #menu="{ row }">
        <Popover
          title="标记"
          v-if="checkPermission(['externaladmin:marketingManagement:userBehavior:mark'])"
          @sureHandler="onRemark(row)"
          @show="onShow(row)"
        >
          <template #tip>
            <div class="mb20">
              <el-input
                type="textarea"
                placeholder="请输入标记内容"
                v-model="currentMark"
                maxlength="80"
                show-word-limit
                :autosize="{ minRows: 4 }"
              >
              </el-input>
            </div>
          </template>
          <template #reference="{ scope: loading }">
            <color-text-btn :loading="loading" size="mini">标记</color-text-btn>
          </template>
        </Popover>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import { checkPermission } from '@/utils'
import ProtoTypeTreeSelect from '@/components/protoTypeTreeSelect'
import { parseTime } from '@/utils'
import { UNSUBSCRIBE } from '@/utils/constant'
import { tableOption } from './module/const'
import { userList as getList, mark } from '@/api/marketingManagementApi'

export default {
  mixins: [
    avueCrud({
      tableOption,
      getList
    })
  ],
  components: {
    ProtoTypeTreeSelect
  },
  data() {
    return {
      resetMergeData: {
        orderItems: [
          {
            column: 'create_time',
            asc: false
          }
        ]
      },
      currentMark: ''
    }
  },
  methods: {
    parseTime,
    checkPermission,
    onShow(data) {
      this.currentMark = data.remark
    },
    async onRemark(row) {
      const { id } = row
      const res = await awaitResolve(
        mark({
          id,
          remark: this.currentMark
        })
      )
      if (res) {
        this.init()
        return this.$message.success('标记编辑成功')
      } else return this.$message.error('标记编辑失败')
    },
    getPlatform({ platformAssociationList }) {
      return platformAssociationList?.map(({ platformName }) => platformName)?.join(',')
    },
    getcategoryName({ categoryAssociationList }) {
      return categoryAssociationList?.map(({ categoryName }) => categoryName)?.join(',')
    },
    isUnsubscribe(data) {
      return data?.userInfo?.isCancellation == UNSUBSCRIBE
    },
    getNickName(data) {
      return data?.userInfo?.nickName
    },
    getAccountName(data) {
      return data?.userInfo?.accountName
    },
    getEmail(data) {
      return data?.userInfo?.email
    },
    getTime(data) {
      return data?.userInfo?.createTime
    }
  }
}
</script>

<style lang="scss">
.user-behavior-tips {
  max-width: 180px;
  line-height: 20px;
}
</style>

<style lang="scss" scoped>
.user-behavior-warpper {
  cursor: pointer;
}
.danger {
  color: red;
}
.cut {
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
