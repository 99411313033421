import request from '@/service/request'

// 用户偏好记录列表
export function userList(data) {
  return request({
    url: '/externaladmin/authService/referenceRecord/complexList',
    method: 'post',
    data
  })
}

// 用户偏好标记
export function mark(data) {
  return request({
    url: '/externaladmin/authService/referenceRecord/remarkUpdate',
    method: 'post',
    data
  })
}

