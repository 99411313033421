var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('avue-crud',{ref:"crud",staticClass:"search-form flex-one-page",attrs:{"option":_vm.tableOption,"data":_vm.tableData,"tableLoading":_vm.tableLoading,"resetMergeData":_vm.resetMergeData,"page":_vm.tablePage},on:{"size-change":_vm.sizeChange,"current-change":_vm.pageChange,"search-change":_vm.searchChange},scopedSlots:_vm._u([{key:"categorySearchForm",fn:function(ref){
var form = ref.form;
return [_c('ProtoTypeTreeSelect',{staticClass:"w100",attrs:{"placeholder":"请选择分类","size":"mini","selectedValue":form['categoryId'],"maxHeight":150,"otherIncluded":true},on:{"update:selectedValue":function($event){return _vm.$set(form, 'categoryId', $event)},"update:selected-value":function($event){return _vm.$set(form, 'categoryId', $event)},"change":_vm.searchChange}})]}},{key:"userName",fn:function(ref){
var row = ref.row;
return [(!_vm.isUnsubscribe(row))?_c('div',[_vm._v(_vm._s(_vm.getNickName(row)))]):_c('div',[_vm._v("——")])]}},{key:"accountName",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getAccountName(row))+" "),(_vm.isUnsubscribe(row))?_c('span',{staticClass:"danger"},[_vm._v("（已注销）")]):_vm._e()])]}},{key:"email",fn:function(ref){
var row = ref.row;
return [(!_vm.isUnsubscribe(row))?_c('div',[_vm._v(_vm._s(_vm.getEmail(row)))]):_c('div',[_vm._v("——")])]}},{key:"remark",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"user-behavior-warpper"},[_c('el-tooltip',{attrs:{"popper-class":"user-behavior-tips","placement":"top","effect":"light","open-delay":300,"disabled":!row.remark,"content":row.remark}},[_c('div',{staticClass:"cut"},[_vm._v(" "+_vm._s(row.remark || '未标记')+" ")])])],1)]}},{key:"platform",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getPlatform(row))+" ")]}},{key:"categoryName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getcategoryName(row))+" ")]}},{key:"time",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.parseTime(_vm.getTime(row)))+" ")]}},{key:"menu",fn:function(ref){
var row = ref.row;
return [(_vm.checkPermission(['externaladmin:marketingManagement:userBehavior:mark']))?_c('Popover',{attrs:{"title":"标记"},on:{"sureHandler":function($event){return _vm.onRemark(row)},"show":function($event){return _vm.onShow(row)}},scopedSlots:_vm._u([{key:"tip",fn:function(){return [_c('div',{staticClass:"mb20"},[_c('el-input',{attrs:{"type":"textarea","placeholder":"请输入标记内容","maxlength":"80","show-word-limit":"","autosize":{ minRows: 4 }},model:{value:(_vm.currentMark),callback:function ($$v) {_vm.currentMark=$$v},expression:"currentMark"}})],1)]},proxy:true},{key:"reference",fn:function(ref){
var loading = ref.scope;
return [_c('color-text-btn',{attrs:{"loading":loading,"size":"mini"}},[_vm._v("标记")])]}}],null,true)}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }